import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Box, Fade, InputAdornment, Paper, TextField } from "@material-ui/core"
// import SuggestShop from "../components/SuggestShop"
import HomeProject from "../components/HomeProject"
import SearchIcon from "@material-ui/icons/Search"

// import image from "../images/logo.png"

// const mockProjects = [
//   {
//     meta: {
//       background_url:
//         "https://gatsbyhomeshopstorage130839-master.s3.eu-west-1.amazonaws.com/public/images/56ede6ac-c39c-45e9-83c5-470804e0ebf6-background.png",
//       city: "Lyon",
//       description:
//         "ido-data utilise les technologies de l’internet des objets et du digital pour vous aider à sauver des vies",
//       fullDescription:
//         "ido-data utilise les technologies de l’internet des objets et du digital pour vous aider à sauver des vies",
//       logo_url:
//         "https://gatsbyhomeshopstorage130839-master.s3.eu-west-1.amazonaws.com/public/images/56ede6ac-c39c-45e9-83c5-470804e0ebf6-logo.shop_Couleur_Site_Web (1)",
//       url: "https://ido-data.fr",
//       zip_code: "69003",
//     },
//   },
//   {
//     meta: {
//       background_url:
//         "https://gatsbyhomeshopstorage130839-master.s3.eu-west-1.amazonaws.com/public/images/56ede6ac-c39c-45e9-83c5-470804e0ebf6-background.png",
//       city: "Lyon",
//       description: "Vous aider à sauver des vies",
//       fullDescription:
//         "ido-data utilise les technologies de l’internet des objets et du digital pour vous aider à sauver des vies",
//       logo_url:
//         "https://gatsbyhomeshopstorage130839-master.s3.eu-west-1.amazonaws.com/public/images/56ede6ac-c39c-45e9-83c5-470804e0ebf6-logo.shop_Couleur_Site_Web (1)",
//       url: "https://ido-data.fr",
//       zip_code: "69003",
//     },
//   },
// ].map(({ meta }) => ({ meta: JSON.stringify(meta) }))

const matchForm = formValue => {
  if (!formValue) {
    return () => true
  }
  const tokens = formValue.toLowerCase().match(/\S+/g)
  console.log({ tokens })
  return item => {
    const str = JSON.stringify(item.meta).toLowerCase()
    return tokens.every(token => str.includes(token))
  }
}

const Field = ({ label, onChange }) => (
  <Box my={2} mx={1}>
    <Paper
      style={{
        width: "100%",
        padding: 10,
      }}
      elevation={5}
    >
      <TextField
        style={{ flex: 1, background: "white", width: "100%", borderWidth: 0 }}
        placeholder={label}
        variant="standard"
        onChange={onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="large" />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
      />
    </Paper>
  </Box>
)

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "hidden",
    background: "rgba(240,240,245)",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(1),
    // padding: theme.spacing(1),
    overflow: "hidden",
    maxWidth: 940,
    alignSelf: "center",
  },
  projects: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gridGap: "1rem",
    marginBottom: 10,
    flex: 1,
  },
  square1: {
    position: "relative",
    // padding: "1rem",
  },
  square2: {
    position: "absolute",
    maxWidth: "100%",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    margin: "auto",
    // padding: 10,
  },
  title: {
    fontWeight: "bold",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

// const meta = [
//   {
//     image: image,
//     address: "2 rue du maine",
//     zip_code: "69003",
//     city: "Lyon",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ip ",
//   },
//   {
//     image: image,
//     address: "2 rue du maine",
//     zip_code: "69003",
//     city: "Lyon",
//     description: "Lorem ipsum dolor sit amet,",
//   },
//   {
//     image: image,
//     address: "2 rue du maine",
//     zip_code: "69003",
//     city: "Lyon",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ip ",
//   },
//   {
//     image: image,
//     address: "2 rue du maine",
//     zip_code: "69003",
//     city: "Lyon",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
//   },
//   {
//     image: image,
//     address: "2 rue du maine",
//     zip_code: "69003",
//     city: "Lyon",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  ",
//   },
//   {
//     image: image,
//     address: "2 rue du maine",
//     zip_code: "69003",
//     city: "Lyon",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  ",
//   },
// ]

export default function Home({ projects, pageType, city }) {
  const classes = useStyles()
  const [formValue, setFormValue] = React.useState("")
  const handleChange = event => setFormValue(event.target.value)
  const filtered = projects.filter(matchForm(formValue.toLowerCase()))
  console.log(formValue, { projects })
  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Typography variant="h5" className={classes.title}>
          {`Réservez chez votre commerçant préféré ${
            city ? `à ${city} ` : ""
          }!`}
        </Typography>
        <Field
          label={pageType === "city" ? "Quoi ?" : "Quoi ? Où ?"}
          onChange={handleChange}
        />
        {/* <div
          style={{
            height: "-webkit-fill-available",
          }}
        > */}
        <Fade in>
          <div className={classes.projects}>
            {filtered.map((project, idx) => (
              <div key={`${project.id}-${idx}`} className={classes.square1}>
                <HomeProject data={project} />
              </div>
            ))}
          </div>
        </Fade>
        {/* </div> */}
      </main>
    </div>
  )
}
