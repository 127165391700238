import { Button } from "@material-ui/core"
import React from "react"

export default function SecondaryButton({ style, inverted, ...props }) {
  const { disabled } = props
  return (
    <Button
      variant={inverted ? "outlined" : "contained"}
      style={{
        ...style,
        ...(inverted ? { border: "2px solid #ff6363" } : {}),
        color: inverted ? "#ff6363" : "white",
        backgroundColor: inverted
          ? "white"
          : disabled
          ? "#ff636388"
          : "#ff6363",
      }}
      {...props}
    />
  )
}
