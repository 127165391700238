import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
// import useMediaQuery from "@material-ui/core/useMediaQuery"
// import CardActionArea from "@material-ui/core/CardActionArea"
// import CardActions from "@material-ui/core/CardActions"
// import CardContent from "@material-ui/core/CardContent"
// import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
// import SimpleInputDialog from "./SimpleInputDialog"
import DateRangeIcon from "@material-ui/icons/DateRange"
// import VisibilityIcon from "@material-ui/icons/Visibility"
import { Box } from "@material-ui/core"
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import { navigate } from "gatsby"
// import Bookwizard from "./Bookwizard"
import SecondaryButton from "./SecondaryButton"

import useIsMobile from "../utils/useIsMobile"

const useStyles = makeStyles(theme => {
  console.log("shadow", { shadow: theme.shadows[3] })
  return {
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      height: "max-content",
      background: "white",
      margin: theme.spacing(1),
    },
    root1: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      background: "white",
      overflow: "hidden",
      width: "100%",
    },
    lightSecondary: {
      background: theme.palette.secondary.main,
      color: "white",
    },
    backButtonContainer: {
      position: "absolute",
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    image: {
      objectFit: "contain",
      width: "100%",
      height: "100%",
    },
    bannerContainer: {
      display: "flex",
      position: "relative",
    },
    avatarContainer: {
      position: "absolute",
      bottom: "-25%",
      width: "auto",
      height: "80%",
      left: "4%",
      overflow: "visible",
      padding: 3,
    },
    avatar: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      // border: "1px solid",
      // width: "auto",
      justifyContent: "flex-start",
      minWidth: 0,
      // position: "absolute",
      // bottom: -20,
      // width: 60,
      // height: "100%",
      // width: "auto",
      aspectRatio: 1,
      // left: "5%",
      border: "5px solid white",
      background: "white",
      boxShadow: theme.shadows[3],
    },
    description: {
      display: "flex",
      flexDirection: "column",
      // flex: 1,
      whiteSpace: "pre-wrap",
      //marginTop: theme.spacing(1),
      //overflow: "hidden",
      textAlign: "justify",
      verticalAlign: "center",
      justifyContent: "center",
      paddingLeft: "4%",
      paddingRight: "4%",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    description1: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      whiteSpace: "pre-wrap",
      textAlign: "justify",
      paddingLeft: "4%",
      paddingRight: "4%",
      WebkitLineClamp: 1,
    },
    footerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      padding: theme.spacing(1),
      //paddingBottom: "4%",
      // paddingLeft: "4%",
      // paddingRight: "4%",
      //paddingTop: theme.spacing(1),
      //position: "absolute",
      //bottom: 0,
      //padding: "8px",
      width: "100%",
    },
    popUpBackground: {
      position: "fixed",
      zIndex: 1,
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      overflow: "auto",
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    popUpPaperDetails: {
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      background: "white",
      padding: 10,
    },
    descriptionContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      overflow: "hidden",
      position: "relative",
    },
    gatsbyImage: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      shadow:
        "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
      width: "auto",
      justifyContent: "flex-start",
      minWidth: 0,
      overflow: "visible",
    },
  }
})

export default function HomeProject({ data }) {
  const isMobile = useIsMobile()
  const classes = useStyles()
  console.log("HomeProject", { data })
  const meta =
    typeof data.meta === "string" ? JSON.parse(data.meta || "{}") : data.meta
  const onClick = () => navigate(`/${data.slug}`)
  // const { background_url = unsplash, logo_url } = meta

  // const [showPopUpDetails, setShowPopUpDetails] = useState(false)

  //responsive design
  // const narrowScreen = useMediaQuery("(max-width:399px)")
  // const narrowSmallScreen = useMediaQuery(
  //   "(min-width:400px) and (max-width:539px)"
  // )
  // const smallScreen = useMediaQuery("(min-width:539px) and (max-width:631px)")
  // const mediumScreen = useMediaQuery("(min-width:632px) and (max-width:798px)")
  // const largeScreen = useMediaQuery("(min-width:799px) and (max-width:947px)")
  // const extraLargeScreen = useMediaQuery("(min-width:947px)")

  // const heightOverView = () => {
  //   if (narrowScreen) {
  //     return "100px"
  //   } else if (smallScreen) {
  //     return "20px"
  //   } else if (mediumScreen) {
  //     return "95px"
  //   } else if (largeScreen) {
  //     return "60px"
  //   } else if (extraLargeScreen) {
  //     return "95px"
  //   } else {
  //     return "50px"
  //   }
  // }

  // const paddingBottomButton = () => {
  //   if (narrowScreen || mediumScreen || largeScreen) {
  //     return "5%"
  //   } else if (extraLargeScreen) {
  //     return "8%"
  //   }
  // }

  // const paddingTopCardContent = () => {
  //   if (narrowSmallScreen || smallScreen) {
  //     return "0px"
  //   } else if (largeScreen) {
  //     return "30px"
  //   } else if (extraLargeScreen) {
  //     return "80px"
  //   } else {
  //     return "70px"
  //   }
  // }

  // const marginBottomCardContent = () => {
  //   if (narrowSmallScreen || smallScreen) {
  //     return "-20px"
  //   } else if (largeScreen) {
  //     return "-10px"
  //   } else {
  //     return "20px"
  //   }
  // }

  // const topAvatar = () => {
  //   if (smallScreen) {
  //     return "156px"
  //   } else if (mediumScreen) {
  //     return "66px"
  //   } else if (extraLargeScreen) {
  //     return "76px"
  //   } else if (largeScreen) {
  //     return "109px"
  //   } else {
  //     return "86px"
  //   }
  // }

  //console.log(heightOverView())

  const { background_url, logo_url } = data
  return (
    <>
      <Card elevation={5} className={classes.root}>
        <div className={classes.root1}>
          <div className={classes.bannerContainer}>
            <GatsbyImage
              alt="banner"
              image={background_url.childImageSharp.gatsbyImageData}
              style={{ width: "100%" }}
            />
            <div className={classes.avatarContainer}>
              {/* <Avatar className={classes.avatar}> */}
              <GatsbyImage
                alt="logo"
                className={classes.gatsbyImage}
                // loading="eager"
                // className={classes.avatarContainer}
                objectFit="contain"
                image={logo_url.childImageSharp.gatsbyImageData}
                style={{ overflow: "visible" }}
                imgStyle={{
                  height: "100%",
                  width: "auto",
                  borderRadius: "50%",
                  border: "5px solid white",
                  background: "white",
                  overflow: "visible",
                  boxShadow:
                    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
                }}
              />
              {/* </Avatar> */}
            </div>
          </div>

          <Box
            style={{
              //overflow: "hidden",
              ...(isMobile ? {} : { height: "240px" }),
              width: "100%",
              padding: 0,
              marginTop: "10%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
              }}
            >
              <Typography
                gutterBottom
                variant="h5"
                style={{
                  WebkitLineClamp: 2,
                  margin: 0,
                  padding: 0,
                  paddingLeft: "4%",
                  paddingRight: "4%",
                  fontWeight: "bold",
                }}
              >
                {data.name}
              </Typography>
              <Typography
                variant="body1"
                noWrap
                style={{
                  paddingLeft: "4%",
                  paddingRight: "4%",
                  WebkitLineClamp: 1,
                  fontWeight: "bold",
                }}
              >
                {`${meta.address || ""} ${meta.zip_code || ""} ${
                  meta.city || ""
                }`}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.description}
                >
                  {meta.description || "test"}
                </Typography>
              </div>
            </div>
            <div
              className={classes.footerContainer}
              // style={{
              //   paddingBottom: 20,
              // }}
            >
              <SecondaryButton
                startIcon={<DateRangeIcon style={{ fontSize: 32 }} />}
                // className={classes.lightSecondary}
                onClick={onClick}
              >
                Réserver
                {/* {context === "" ? "Réserver" : "Détails"} */}
              </SecondaryButton>
              {/* <Paper
                component={IconButton}
                elevation={5}
                style={{ background: "white" }}
                onClick={() => setShowPopUpDetails(true)}
              >
                <MoreHorizIcon color="primary" />
              </Paper> */}
            </div>
          </Box>
        </div>
      </Card>
      {/* {showPopUpDetails && (
        <div className={classes.popUpBackground}>
          <Paper
            className={classes.popUpPaperDetails}
            style={{
              margin: isMobile ? 30 : 60,
              position: "relative",
              height: "-webkit-fill-available",
              overflow: "auto",
            }}
          >
            <Paper
              component={IconButton}
              elevation={5}
              style={{
                background: "white",
                position: "absolute",
                top: isMobile ? 40 : 7,
                right: isMobile ? 1 : 17,
              }}
              onClick={() => setShowPopUpDetails(false)}
            >
              <CancelIcon color="secondary" />
            </Paper>
            <ProjectDetails data={data} context="details" flag={false} />
          </Paper>
        </div>
      )} */}
    </>
  )
}
